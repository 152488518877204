import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import { auth } from "../../../firebase"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  console.log('saga register', user, process.env.REACT_APP_DEFAULTAUTH, getFirebaseBackend())
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const response = yield call(
      //   fireBaseBackend.registerUser,
      //   user.email,
      //   user.password,
      //   user.username
      // )

      // console.log('firebase payload', response)
      auth.createUserWithEmailAndPassword(user.email, user.password)
        .then((userAuth) => {
          userAuth.user.updateProfile({
            displayName: user.username,
          })
            .then(() => {
              auth.currentUser.sendEmailVerification().then(function () {
                let response = {
                  userName: user.username,
                  password: user.password,
                  email: user.email
                }
                console.log('/emailvarification', userAuth)
                registerUserSuccessful(response)
              }).catch(function (error) {
                console.log('email error is : ', error);
              });
            })
        }).catch(error => alert(error))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      console.log(response)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
