import React from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
//Import Image
import SlideImage from "../../assets/images/slider/complete.png"
// import Inbox from './Inbox';
import { useEffect } from "react"
import { db } from "../../firebase"
import LatestTransaction from "./latest-transaction"
import { useState } from "react"

const Ending = () => {
    const [polData, setPolData] = useState([])
    const query = new URLSearchParams(window.location.search);
    const pid = query.get('pid')
    const status = query.get('st')
    const uid = query.get('uid')
    const [ip, setIp] = useState('')
    const [country, setCountry] = useState('')
    let currentDate = new Date().toLocaleString("en-Us", { timeZone: 'Asia/Kolkata' });

    const fetchPolData = async () => {
        await db
            .collection('pols')
            .orderBy('timestamp', 'desc')
            .onSnapshot(
                snapshot => {
                    setPolData(snapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })
                    ))
                })
    }


    const getMyIp = () => {
        fetch('https://api.ipify.org?format=json').then(response => {
            return response.json();
        }).then((res) => {
            console.log('response ip', res)
            setIp(res.ip)
        }).catch((err) => console.error('Problem fetching my IP', err))
        fetch('https://api.country.is/').then(response => {
            return response.json();
        }).then((res) => {
            console.log('response ip', res)
            setCountry(res?.country)
            // setIp(res.ip)
        }).catch((err) => console.error('Problem fetching my IP', err))
    }
    console.log('my ip', ip)

    console.log('poldata', polData)
    useEffect(() => {
        fetchPolData()
        getMyIp()
    }, [])

    // let checkExist = []
    console.log({ pid, uid, status })

    const addPolDetails = () => {
        let checkExist = polData.filter((e) => uid == e?.data?.uid)
        console.log(checkExist, "check")
        if (checkExist.length > 0) {
            // alert("Please change uid")
            return false
        } else {
            console.log('ip', ip)
            if (pid && uid && status) {
                db.collection("pols").add({
                    pid: pid,
                    uid: uid,
                    status: status,
                    timestamp: currentDate,
                    ip: ip,
                    country: country
                })
                fetchPolData()
                console.log('added succesfully', { pid, uid, status })
            }
        }
    }
    useEffect(() => {
        if (polData.length > 0) {
            addPolDetails()
        }
    }, [pid, uid, status, polData])




    let data = {
        pid: pid,
        uid: uid,
        status: status,
        timestamp: currentDate,
        ip: ip,
        country: country
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">

                            <Link to="/">
                                <h4 className="page-title mb-0 font-size-18"><i className="mdi mdi-home-outline"></i> Home</h4>
                            </Link>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item active">Welcome to Alpha Research Survey</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </Row>
                <Row>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', width: '100%', flex: 1, justifyContent: 'center' }}>
                        <div lg={4} style={{ flex: 0.3 }}>
                            <img src={SlideImage} />
                        </div>
                        <div lg={8} style={{ flex: 0.7 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
                                <div>

                                    {/* <h1 style={{ fontSize: '3rem' }}>Thanks For Completing Survey</h1> */}
                                    <h2 className="text-center">Your Survery is {status.includes('1') ? "Completed" : status.includes('2') ? "Disqualified" : status.includes('3') ? "Quta Full" : null}</h2>
                                    <div className="mt-4">
                                        <LatestTransaction data={data} from="ending" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Row>
            </div>

        </React.Fragment>
    )
}

export default Ending