import React from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
//Import Image
import SlideImage from "../../assets/images/slider/complete.png"
// import Inbox from './Inbox';
import { useEffect } from "react"
import { db } from "../../firebase"
import LatestTransaction from "./latest-transaction"
import { useState } from "react"

const SurveyTable = () => {
    const [polData, setPolData] = useState([])
    const query = new URLSearchParams(window.location.search);
    const pid = query.get('pid')
    const status = query.get('st')
    const uid = query.get('uid')
    let currentDate = new Date()
    console.log('poldata', polData)


    console.log({ pid, uid, status })


    useEffect(() => {
        fetchPolData()
    }, [])


    const fetchPolData = async () => {
        await db
            .collection('pols')
            .orderBy('timestamp', 'desc')
            .onSnapshot(
                snapshot => {
                    setPolData(snapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })
                    ))
                })
    }

    const checkExist = polData.filter((e) => pid == e?.data?.pid)
    console.log(checkExist, "check")

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">

                            <Link to="/">
                                <h4 className="page-title mb-0 font-size-18"><i className="mdi mdi-home-outline"></i> Home</h4>
                            </Link>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item active">Welcome to Alpha Research Survey</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </Row>
                <Row>
                    <Row>
                        <LatestTransaction data={polData} from="ending" />
                    </Row>

                </Row>
            </div>

        </React.Fragment>
    )
}

export default SurveyTable