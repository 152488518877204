import React from "react"
import { Card, CardBody, Table, CardTitle, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Link } from "react-router-dom"
import Moment from 'react-moment';

const LatestTransaction = (props) => {
    const { data } = props
    const convertTimestamp = (time) => {
        // let date = time && time.toDate();
        // let mm = time && date.getMonth() + 1;
        // let dd = time && date.getDate() - 1;
        // let yyyy = time && date.getFullYear();

        // date = dd + '/' + mm + '/' + yyyy;

        // return date;
    }

    function timeTo12HrFormat(time) {   // Take a time in 24 hour format and format it in 12 hour format
        var time_part_array = time.split(":");
        var ampm = 'AM';

        if (time_part_array[0] >= 12) {
            ampm = 'PM';
        }

        if (time_part_array[0] > 12) {
            time_part_array[0] = time_part_array[0] - 12;
        }

        let formatted_time = time_part_array[0] + ':' + time_part_array[1] + ':' + time_part_array[2] + ' ' + ampm;

        return formatted_time;
    }
    const convertTimestamp2 = (time) => {
        // let date = time && time.toDate();
        // let mm = time && date.getMinutes();
        // let hh = time && date.getHours();
        // let ss = time && date.getSeconds();

        // date = hh + ':' + mm + ':' + ss;
        // let formatted_time = timeTo12HrFormat(date)

        // return formatted_time;
    }
    return (
        <Col lg={12}>
            <Card>
                <CardBody>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <CardTitle className="h4 mb-4">Latest Pols</CardTitle>
                        <Link to="/surveydata" className="btn btn-primary btn-sm">View All</Link>
                    </div>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">IP</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Project Id</th>
                                    <th scope="col">UID</th>
                                    <th scope="col">Time</th>
                                    <th scope="col" colSpan="2">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data &&
                                    data
                                        .slice(0, 100)
                                        .sort((a, b) => a.data.timestamp - b.data.timestamp)
                                        .map((e) => (
                                            <tr>
                                                <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {e?.data?.timestamp}
                                                    </Moment>
                                                </td>
                                                <td>{e?.data?.ip}</td>
                                                <td>{e?.data?.country || "Null"}</td>
                                                <td>
                                                    <Link to="#" className="text-body fw-medium">{e?.data?.pid}</Link>
                                                </td>
                                                <td>{e?.data?.uid}</td>
                                                <td>
                                                    <Moment format="hh:mm:ss">
                                                        {e?.data?.timestamp}
                                                    </Moment>
                                                </td>
                                                <td><span className={e?.data?.status.includes('1') ? "badge badge-soft-success  font-size-12" : e?.data?.status.includes('2') ? "badge badge-soft-danger  font-size-12" : e?.data?.status.includes('3') ? "badge badge-soft-warning  font-size-12" : "badge  font-size-12"}>{e?.data?.status.includes('1') ? "Completed" : e?.data?.status.includes('2') ? "Disqualified" : e?.data?.status.includes('3') ? "Quta Full" : null}</span>
                                                </td>
                                                {/* <td><Link to="#" className="btn btn-primary btn-sm">View</Link></td> */}
                                            </tr>
                                        ))
                                }

                            </tbody>
                        </Table>
                    </div>
                    {
                        false && (
                            <div className="mt-3">
                                <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                                    <PaginationItem>
                                        <PaginationLink to="#">Previous</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem><PaginationLink to="#">1</PaginationLink></PaginationItem>
                                    <PaginationItem className="active"><PaginationLink to="#">2</PaginationLink></PaginationItem>
                                    <PaginationItem><PaginationLink to="#">3</PaginationLink></PaginationItem>
                                    <PaginationItem><PaginationLink to="#">Next</PaginationLink></PaginationItem>
                                </Pagination>
                            </div>
                        )
                    }
                </CardBody>
            </Card>
        </Col>
    )
}

export default LatestTransaction