import React, { useState } from "react"
import { Row, Col, CardBody, Card } from "reactstrap"
import { Link } from "react-router-dom"


//Import Image
import SlideImage from "../../assets/images/slider/team1.png"
import ServiceImage from "../../assets/images/slider/service.png"
// import Inbox from './Inbox';
import { useEffect } from "react"
import { db } from "../../firebase"
import HomeData from "../../data/home.json";


function getNewTime() {
    let d1 = new Date(),
        d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 1);
    let obj = { current: d1.toLocaleTimeString(), new: d2.toLocaleTimeString() }
    console.log(obj)
    return obj;
}

const Home = () => {
    const query = new URLSearchParams(window.location.search);
    const pid = query.get('pid')
    const gid = query.get('gid')
    const tm = query.get('tm')
    let date = new Date()
    var d1 = new Date(),
        d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + Number(tm));
    const [newTime, setNewTime] = useState(d2.toLocaleTimeString())

    console.log('new time func', newTime)

    const checkTimeForPost = (newTime) => {
        console.log(date?.toLocaleTimeString(), newTime?.toLocaleTimeString(), 'current and new time')

    }


    getNewTime()


    console.log({ pid, gid, tm })

    // const addPolDetails = () => {
    //     if (pid && uid && status) {

    //         db.collection("pols").add({
    //             pid: pid,
    //             uid: uid,
    //             status: status,
    //             timestamp: currentDate,
    //         })
    //         console.log('added succesfully', { pid, uid, status })
    //     } else {
    //         return false
    //     }
    // }
    useEffect(() => {
        if (pid && gid) {
            window.location.href = `https://www.epitome-rs.com/surveyInitiate.php?gid=${gid}&pid=${pid}`
        }
    }, [pid, gid])


    // useEffect(() => {
    //     var timer = setInterval(() => {
    //         console.log(new Date().toLocaleTimeString(), d2?.toLocaleTimeString(), 'current and new time')

    //         if (new Date().toLocaleTimeString() === d2?.toLocaleTimeString()) {
    //             console.log('Hit add')
    //         }
    //     }, 1000)
    //     return function cleanup() {
    //         clearInterval(timer)
    //     }

    // });



    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">

                            <h4 className="page-title mb-0 font-size-18"><i className="mdi mdi-home-outline"></i> Home</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item active">Welcome to Alpha Research Survey</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </Row>
                <Row>
                    <Row>
                        <Col lg={6}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
                                <div>

                                    <h1 style={{ fontSize: '4rem' }}>Full Solution
                                        for your Business
                                        Development</h1>
                                    <Link to="/login" className="btn btn-primary btn-lg">Get Started</Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div>
                                <img src={SlideImage} width={700} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '5%' }}>
                        <center>

                            <div>
                                <h2>FEATURES</h2>
                                <p className="page-head my-4 font-size-32" style={{ fontSize: '3rem' }}>
                                    We are <span className='text-primary'>different</span> because...
                                </p>

                                <p className="page-title font-size-18" style={{ width: '50%', marginBottom: '5%' }}>Pleasure rationally encounter consequences that are extremely
                                    painful. Nor again is there anyone who loves or pursues or
                                    desires to obtain</p>

                                <Link to="#" className="btn btn-primary btn-lg round">Get Started</Link>

                            </div>
                        </center>

                        <Row className="mt-4">
                            {HomeData[2].iconBox &&
                                HomeData[2].iconBox.map((e, key) => (
                                    <Col lg={4} key={key}>
                                        <Card className="bg-primary">
                                            <CardBody>
                                                <Row className="justify-content-end">
                                                    <div className="col-8">
                                                        <div className="my-4">

                                                            <img src={e?.icon} alt={e.icon} style={{ filter: 'invert(1)' }} />
                                                        </div>
                                                    </div>
                                                </Row>
                                                <div className="text-white">
                                                    <h5 className="text-white">{e.title}</h5>
                                                    <p>{e.excerpt}</p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    </Row>
                    <Row style={{ marginTop: '5%' }}>
                        <Col lg={6}>
                            <div>
                                <img src={ServiceImage} width={700} />
                            </div>
                        </Col>
                        <Col lg={6}>
                            {/* <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', height: '80vh' }}> */}
                            <div>
                                <h2 className="text-grey">About Us</h2>
                                <h1 style={{ fontSize: '4rem' }}>Best Survery Services in the Town</h1>
                                <p className="page-title font-size-18" style={{ width: '80%', marginBottom: '5%' }}>Pleasure rationally encounter consequences that are extremely
                                    painful. Nor again is there anyone who loves or pursues or
                                    desires to obtain</p>
                                <p className="page-title font-size-18 text-bold" style={{ width: '80%', marginBottom: '5%' }}>Pleasure rationally encounter consequences that are extremely painful. Nor again is there</p>
                                <Link to="#" className="btn btn-primary btn-lg">Learn More</Link>
                            </div>
                            {/* </div> */}
                        </Col>

                    </Row>

                    <Row style={{ marginTop: '5%' }}>
                        <center>

                            <div>
                                <h2>Services</h2>
                                <p className="page-head my-4 font-size-32" style={{ fontSize: '3rem' }}>
                                    Check <span className='text-primary'>Our</span> Services...
                                </p>

                                <p className="page-title font-size-18" style={{ width: '50%', marginBottom: '5%' }}>Pleasure rationally encounter consequences that are extremely
                                    painful. Nor again is there anyone who loves or pursues or
                                    desires to obtain</p>


                            </div>
                        </center>

                        <Row className="mt-4">
                            {HomeData[2].iconBox &&
                                HomeData[2].iconBox.map((e, key) => (
                                    <Col lg={4} key={key}>
                                        <Card className="bg-soft-primary rounded-square">
                                            <CardBody>
                                                <Row className="justify-content-end">
                                                    <div className="col-8">
                                                        <div className="my-4">

                                                            <img src={e?.icon} alt={e.icon} />
                                                        </div>
                                                    </div>
                                                </Row>
                                                <div className="text-black">
                                                    <h5 className="text-black">{e.title}</h5>
                                                    <p>{e.excerpt}</p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    </Row>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default Home